.loading-page {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

#ide-root > .loading-page {
    background-color: white; /* Override hyperfiddle blue for loading */
}

/*.loading-page:after {*/
    /*content: "";*/
    /*position: absolute;*/
    /*width: 200%;*/
    /*height: 200%;*/
    /*top: -50%;*/
    /*left: -50%;*/
    /*opacity: .2;*/
    /*background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='4em' width='10em'><text y='1em' fill='%2342a2b5' font-size='2em'>loading...</text></svg>");*/
    /*-webkit-transform: rotate(-30deg);*/
    /*transform: rotate(-30deg);*/
/*}*/

.loading-page .logo-callout {
    top: 20%;
    margin: auto;
    padding: 2em 3em;
    position: relative;
    text-align: center;
    /*background: linear-gradient(to right, rgba(0,0,0,0), white calc(50% - 10em), white calc(50% + 10em), rgba(0,0,0,0));*/
    /*border-color: #42a2b5;*/
    /*border-style: solid;*/
    /*border-width: 3px 0;*/
    z-index: 1;
}

.loading-page .logo-callout img {
    width: 6em;
    height: 6em;
    vertical-align: middle;

    /*
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    */
}

@keyframes spin {
    0% {
        transform:rotate(0deg);
    }
    50% {
        transform:rotate(180deg);
    }
    100% {
        transform:rotate(360deg);
    }
}

.loading-page .logo-callout .brand {
    font-family: Menlo;
    letter-spacing: -1px;
    font-weight: 400;
    font-style: italic;
    color: #f269b4;

    display: inline-block;
    margin-left: 1em;
    font-size: 2em;
    vertical-align: middle;
}

.loading-page .additional-content {
    margin-top: 2em;
}

.loading-page .hyperfiddle-logo {
    margin-top: 200px;
    width: 100px;
    height: 100px;
    margin-left: calc(50% - 50px);
    margin-top: 0;
    transform: scale(1.5);
}

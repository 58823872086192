th.hyperfiddle.field > :first-child label.hyperfiddle, /* tooltips nest the label a couple layers down */
div.hyperfiddle.field > :first-child label.hyperfiddle, /* tooltips here too; also be careful with the recom datepicker's label */
div.hyperfiddle.field > label.hyperfiddle { /* Explicit userland :label without recom tooltip wrap */
    text-decoration: underline dashed #ccc;
    text-underline-position: under;
    cursor: help;
    font-weight: 700;
}

.hyperfiddle.docstring {
    color: white;
    max-width: 378px; /* 378 fits `:db.type/string :db.cardinality/one :db.unique/value` */
}

.hyperfiddle.docstring pre {
    /* recom overrides */
    padding: 0;
    color: unset;
    background-color: #555;
    border-color: #666;
}

.hyperfiddle.docstring code {
    color: unset;
    background: unset;
    border: unset;
    font-size: .7em;
    padding: 0;
}

.hyperfiddle.docstring p {
    margin: 0;
    font-size: 1.0rem; /* undo the typography */
}

.hyperfiddle.field label sup {
    color: #ccc;
}

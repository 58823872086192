table.hyperfiddle {
    width: 100%;
    table-layout: fixed; /* possibly controversial */
}

table.hyperfiddle thead th {
    font-weight: normal;
    background-color: #dbdbdb;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
}

table.hyperfiddle thead th label.hyperfiddle {
    line-height: 1.8; /* this is what gives the row height */
    display: inline-block;
}

table.hyperfiddle thead th label.hyperfiddle.sortable:after {
    content: "↕";
}

table.hyperfiddle thead th label.hyperfiddle.sortable.asc:after {
    content: "↓";
}

table.hyperfiddle thead th label.hyperfiddle.sortable.desc:after {
    content: "↑";
}

table.hyperfiddle td,
table.hyperfiddle th {
    border: 1px solid #a8a8a8;
    background-color: #fff;
    padding: 0;
    line-height: normal;
    overflow: visible;
}

table.hyperfiddle tr:hover td{
    background-color: rgba(0,0,0, 0.05);
}

table.hyperfiddle > tbody > tr > td {
    border-color: inherit;
}

/* notably not select, it's fatter */
table.hyperfiddle td.hyperfiddle > .hyperfiddle-input-group > input.hyperfiddle,
table.hyperfiddle td.hyperfiddle > .hyperfiddle-input-group > div.hyperfiddle /* checkboxes in tables, see src view */ {
    padding-top: 4px;
    padding-bottom: 4px;
}

.table-controls > select {
    width: auto;
}

th label .hyperfiddle-help {
    border-color: #888;
}

td.hyperfiddle {
    vertical-align: top;
}

/* table cells don't have labels. Perhaps, also do case without input group wrap? */
td > .hyperfiddle-input-group > input,
td > .hyperfiddle-input-group > .editable-select > input,
td > .hyperfiddle-input-group > textarea,
td > .hyperfiddle-input-group > .CodeMirror,
td > .hyperfiddle-input-group > input,
td > .hyperfiddle-input-group > .editable-select > input,
td > .hyperfiddle-input-group > textarea,
td > .hyperfiddle-input-group > .CodeMirror {
    border: none; /* table grid is sufficient */
    width: 100%;
}

th > .hyperfiddle.ui {
    float: right;
}

.hyperfiddle.alert {
    margin-bottom: 0; /* disable alert padding so it touches the search box that fixes it */
}

table.hyperfiddle .info-box_notice{
    border: 0;
    padding: 0.75rem;
}

table.hyperfiddle-table-picker {
    display: block;
}

table.hyperfiddle-table-picker > thead {
  display: block;
}

table.hyperfiddle-table-picker > tbody {
  display: block;
  height: 15em;
  overflow: scroll;
}

table.hyperfiddle-table-picker.full-size > tbody {
    height: initial;
}


table.hyperfiddle-table-picker > * > tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.hyperfiddle-table-picker > tbody > tr > td {
  border-top: none;
}

.hyperfiddle-table-picker-overlay {
    position: relative;
}

.hyperfiddle-table-picker-overlay .hyperfiddle-table-picker-search {
    position:absolute;
    width:100%;
    background-color: white;
    z-index: 1;
    padding-bottom: 1rem;
}

.hyperfiddle-table-picker-overlay .hyperfiddle-table-picker-result {
    margin-top: 3rem;
}

.hyperfiddle-table-picker-overlay .hyperfiddle-table-picker-search input + * {
    display:none;
}

.hyperfiddle-table-picker-overlay .hyperfiddle-table-picker-search:focus-within
{
    box-shadow: 0 2rem 4rem -1rem rgba(0, 0, 0, 0.33);
}

.hyperfiddle-table-picker-overlay .hyperfiddle-table-picker-search:focus-within input + *
{
    display:block;
}

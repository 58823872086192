.hyperfiddle-staging-editor-cmp .nav-tabs {
    border: none;
}

.hyperfiddle-staging-editor-cmp .nav > li.active {
    margin-bottom: -1px;
    border-bottom: 1px solid white;
}

.hyperfiddle-staging-editor-cmp .nav > li > a {
    padding: 0;
    border: none;
}

.hyperfiddle-staging-editor-cmp .nav > li > a > span {
    padding: 3px 6px;
    display: block;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.hyperfiddle-staging-editor-cmp .nav > li.active > a > span {
    border-bottom-color: transparent !important;
}

.hyperfiddle-staging-editor-cmp .hyperfiddle-stage-content {
    border-width: 1px;
    border-style: solid;

}

.hyperfiddle-stage-content .CodeMirror { /* :not(.CodeMirror-focused) */
    /*height: 3em;*/
    height: 10em;
    font-size: 12px;
}
/*.hyperfiddle-stage-content .CodeMirror-focused {*/
    /*height: 10em;*/
/*}*/
.hyperfiddle-stage-content .CodeMirror-hscrollbar {
    overflow-x: hidden;
}

.hyperfiddle-staging-editor-cmp ul {
    display: inline-block;
}

.hyperfiddle-staging-editor-cmp .CodeMirror {
    border: 0; /* inline style color border */
}

.hyperfiddle-staging-editor-cmp .stage-dirty {
    background-color: #FFFF66;
}

/*.hyperfiddle-staging-popover-button {*/
    /*display: inline;*/
/*}*/

/*.hyperfiddle-staging-popover-button .hyperfiddle-popover-body {*/
    /*width: 500px; !* hack because our default styles cause the popovers to expand off screen on tab change*!*/
/*}*/

/*.hyperfiddle-staging-popover-button .CodeMirror {*/
    /*height: 200px;*/
/*}*/

.hyperfiddle-staging-popover-button button.close-popover {
    float: right;
}

.btn.btn-sm.hf-btn-xs {
    padding: 0 .5rem; /* twbs btn-sm is .25rem .5rem */
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.hyperfiddle-stage-actions {
    border-bottom: 1px solid #eee;
}

.CodeMirror:not(.CodeMirror-focused) ~ .hyperfiddle-stage-actions {
    /* Hide the stage actions until the codemirror is focused */
    /*display: none !important;*/
}

.hyperfiddle-stage-actions {
    background: #fff; /* IDE fiddles (fiddle-src, env, etc) inherit blue bg, match the codemirror */
}

p, .p {
    font-size: 1.2rem; /* intended for typography only (markdown) */
    margin-top: 0;
    margin-bottom: 0; /* twbs sets to 1em */
}

.unp {
    font-size: 1.0rem;
}

.lead {
    font-size: 1.4em; /* bootstrap uses 1.25rem, but our root font is the data size. */
}

p, div.p { /* not li.p */
    /* Margin here will cause breaks in the form-cell-border-wrap */
    padding-bottom: 5px;
    padding-top: 5px;
}

li > p, li > div.p {
    padding-bottom: 0;
    padding-top: 0;
}

pre {
    /*border: 0;*/ /* Wrapper border is helpful in blog posts. */
    word-wrap: unset;
    word-break: unset;
}

code {
    color: inherit; /* kill twbs wacky red color, this also lets code-anchors be blue. */
}

pre code {
    border: 0; /* Remove TWBS border inside border ??? */
    border: unset; /* markdown puts code inside pre, but bootstrap borks that style with a weird border */
    white-space: unset;
}

pre pre {
    /*Our error component does this*/
    border: 0;
    padding: 0;
    margin: 0;
}

blockquote {
    /* add more space around blockquotes e.g. dustingetz.com */
    padding: 0 15px;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

blockquote {
    color: gray;
}

blockquote p, blockquote .p {
    margin: 0;
}

blockquote p + p, blockquote div.p + div.p {
    margin-top: 7px;
}

/*.markdown h4 {*/
/*margin-bottom: 20px;*/
/*margin-top: 30px;*/
/*}*/

p img, div.p img {
    max-width: 100%;
}

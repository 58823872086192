/* :swing/display-sub */
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-display .hyperfiddle.attribute.-sub-id
{ display: none; }
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-display .hyperfiddle.attribute.-google-display-name
{ padding-top: 0; }
/*img.sub-profile { border-radius: 50%; width: 100px; }*/

/* Hide the hyperfiddle :identity links, they are placed elsewhere by the custom renderer */
.hyperfiddle.display-mode-user.swinged-rosie-subs-sub-display .hyperfiddle.attribute.-google-index-normalized-email a
{ display: none;}
.hyperfiddle.display-mode-user.swinged-rosie-subs-sub-display .hyperfiddle.attribute.-google-index-normalized-email
{ position: relative; }
.hyperfiddle.display-mode-user.swinged-rosie-subs-sub-display .hyperfiddle.attribute.-google-index-normalized-email input
{ position: absolute;
  /* Here's a little lesson in trickery: We need to hide unwrapped children in this input
     group, but we don’t have any way to select it. By setting the input as
     absolute in a relative parent, the text moves up and slips underneath it.
  */
}

.hyperfiddle.display-mode-user.-swing-sub-rename .hyperfiddle.attribute.-sub-id { display: none; }

/* :swing/sub-blocked-list */
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-blocked-list .hyperfiddle.attribute.-sub-id,
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-blocked-list .hyperfiddle.attribute.-google-display-name,
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-blocked-list .hyperfiddle.attribute.-sub-photo
{ display: none; }
.hyperfiddle.display-mode-user.-swinged-rosie-subs-sub-blocked-list .hyperfiddle.attribute.-school-_blocked-subs
{ padding-top: 0; }
img.sub-profile { border-radius: 50%; width: 100px; }

/* :swing/display-school */
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-id,
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-name
{ display: none; }
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-street-address
{ padding-top: 0; }

/* :swing/school-requests */
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-id,
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-name
{ display: none; }
.hyperfiddle.display-mode-user.-swinged-rosie-schools-display-school .hyperfiddle.attribute.-school-street-address
{ padding-top: 0; }

/* :swing/school-requests* */
.-sub-req-time--interval-end-offset,
.-sub-req-time--interval-start-offset,
.-sub-req-time--db-id { display: none; }

/* :swing/school-base-pay-rates */
.hyperfiddle.display-mode-user.-swing-school-base-pay-rates .hyperfiddle.attribute.-school-name,
.hyperfiddle.display-mode-user.-swing-school-base-pay-rates .hyperfiddle.attribute.-school-id
{ display: none; }
.hyperfiddle.display-mode-user.-swing-school-base-pay-rates .hyperfiddle.attribute.-school-base-pay-rates
{ padding-top: 0; }

/* :swing/sub-request */
.-sub-req-time--interval-end-offset,
.-sub-req-time--interval-start-offset,
.-sub-req-time--db-id { display: none; }

/* :swing/create-admin */
.hyperfiddle.display-mode-user.swinged-rosie-create .hyperfiddle.attribute.-swinged-rosie-create-admin { display: none; }
.hyperfiddle.display-mode-user.swinged-rosie-create .hyperfiddle.attribute.-swinged-rosie-create-admin
  + .hyperfiddle.attribute { padding-top: 0; }

.rosie-index dt { font-weight: unset; }
.rosie-index dd { margin-left: 25em; }


/* :swing/sub-quals */

.hyperfiddle.-swinged-rosie-subs-quals .field.hyperfiddle.-swinged-rosie-subs-quals.-sub-attr-keyword{
    overflow: hidden;
    white-space: nowrap;
}

.hyperfiddle.swinged-rosie-subs-sub-display .field.attribute.-google-email input.attribute.-google-email ~ * {
    display : none;
}



.hf-simple-popover-content{

    position: absolute;
    z-index:3;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    width: fit-content;
    height: fit-content;

    max-width: 80vw;
    max-height: 50vh;

    top: 20vh;

    background-color: white;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    padding: 1rem;

    display: none;
}

.hf-simple-popover-content > .hyperfiddle-input-group > textarea{
    min-width: 50vw;
    min-height: 10rem;
    line-height: 1.25rem;
    padding: 1rem;
}

.hf-simple-popover{
    overflow: hidden;
}

.hf-simple-popover > .hyperfiddle-input-group > input{
    padding: 4px 0 4px 5px;
    border: none;
}

.hf-simple-popover:focus-within  .hf-simple-popover-content {
    display: inherit;
}

.hf-simple-popover:focus-within:after{
    content:"";
    overflow: hidden;
    position: fixed;
    z-index:2;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0, 0.1);
}

.right {
    float: right;
}

/* Matters for code-inline-block (links editor) */
.truncate {
    /*max-width: 0;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.half-width {
    width: 50%;
    display: inline-block;
}

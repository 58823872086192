.hyperfiddle-logo {
  background: #ff70b0;
  position: relative;
}


.hyperfiddle-logo[animate] {
  /* animation: logo-animation 5s infinite; */
}

.hyperfiddle-logo > .hyperfiddle-logo-square {

  position: absolute;
  top: 22%;
  left: 46%;
  width: 12%;
  height: 19%;
  background: #fff;
  transform: skew(-8deg);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}

.hyperfiddle-logo[animate] > .hyperfiddle-logo-square {
    --animation-time: 1.7s;
    --delay: 0.4s;
    animation: roundabout var(--animation-time) infinite;
    animation-timing-function: ease-in-out;
    animation-delay: var(--delay);
}

.hyperfiddle-logo:not([animate]) > .hyperfiddle-logo-square[delay] {
  transform: translate(-5%, 38%) skew(-8deg);
}

.hyperfiddle-logo[animate] > .hyperfiddle-logo-square[delay] {
  animation-delay: calc(var(--delay) - (var(--animation-time) / 2)) !important;
}


@keyframes logo-animation {
  50% {
    /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
    transform: scale(1.55);

  }
}

@keyframes roundabout {
  0% {  /* position 1 start */
    transform: translate(0px, 0px) skew(-8deg);
  }

  10% { /* position 2 */
    transform: translate(20px, 0px) skew(-15deg);
  }

  20% { /* position 3 */
     transform: translate(20px, 38px) skew(-30deg);
  }

  30% { /* position 1a end */
    transform: translate(-5px, 38px) skew(-8deg);
  }

  50% { /* position 1a start */
      transform: translate(-5px, 38px) skew(-8deg);
  }

  60% { /* position 2a */
    transform: translate(-23px, 38px) skew(-15deg);
  }

  70% { /* position 3a */
    transform: translate(-23px, 0px) skew(-30deg);
  }

  80% { /* position 1 end */
    transform: translate(0px, 0px) skew(-8deg);
  }

  100% {
    transform: translate(0px, 0px) skew(-8deg);
  }
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

dt {
    float: left;
    clear: left;
    margin-right: 10px;
}

input {
    font: inherit;
    padding-left: 5px;
}

.hyperfiddle-error fieldset legend {
    font-size: 3em;
}

.hyperfiddle-error fieldset code {
    font-size: 1.3em;
}

.hyperfiddle-warning{
    background-color:  #fdfdea;
    border-radius: 0.35rem;
    color: #8f4e13;
    padding: 1rem;
    margin: 0.5rem 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
}

@keyframes  hyperfiddle-blink {
    50% {
        opacity: 0.6;
    }
}

.hyperfiddle-error-toast {
    background-color: #FEF2F2;
    border-radius: 0.35rem;
    color: #991B1B;
    padding: 2rem;
    margin: 0.5rem 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    justify-content: start;
    animation: hyperfiddle-blink 0.6s ease 4;
}

main.hyperfiddle {
    padding-bottom: 300px;
}

.alpha-nav {
    color: red;
    background-color: wheat;
    text-transform: uppercase;
    text-align: center;
}

table.hyperfiddle thead td label {
    float: left;
}

button.hyperfiddle,
a.hyperfiddle {
    line-height: 1.2;
}

button.hyperfiddle {
    padding: 0 2px;
    margin: 1px;
    border-radius: 5px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1);
    background-color: white; /* device consistency */
}

.popover-content .hyperfiddle-popover-actions { /* a bootstrap 3 class generated by recom, i think. */
    padding: 9px 14px; /* the values from before twbs4 */
    /*padding: .5rem .75rem; !* copied from twbs4 .popover-body, but these assume root font is the big font. *!*/
    display:flex;
    align-content: flex-start;
}

.hyperfiddle-popover-body {
    max-width: 60vw;
    min-width: 400px;
}

.hyperfiddle.display-mode-api div.iframes {
    margin-top: 2em;
}

.hyperfiddle.display-mode-api dl {
    margin-top: .5em;
}

.hyperfiddle.display-mode-api dt {
    width: 100%;
    margin: 0;
    border: 1px solid hotpink;
    border-bottom-style: dashed;
    font-weight: normal;
}

.hyperfiddle.display-mode-api dt > .CodeMirror,
.hyperfiddle.display-mode-api dd > .CodeMirror {
    border: none;
}

.hyperfiddle.display-mode-api dd {
    margin: 0 0 1em 0;
    border: 1px solid hotpink;
}

.-hyperfiddle-ide-preview #root .display-mode-xray.ui .ui,
.-hyperfiddle-ide-preview #root .display-mode-xray .-hf-iframe.ui {
    border: 1px solid hotpink;
}

.-hyperfiddle-ide-preview #root button.hyperfiddle {
    border: 1px dotted hotpink;
}

.-hyperfiddle-ide-preview #root a.hyperfiddle {
    border-bottom: 1px solid hotpink;
}

.-hyperfiddle-ide .radio-group {
    padding-right: 2px;
}

ul.hfrecom-anchor-tabs {
    padding-left: 0;
    margin-bottom: 0;
}

.hfrecom-anchor-tabs li {
    display: inline;
}

ul.hfrecom-anchor-tabs li + li {
    margin-left: .5em;
}

.hfrecom-anchor-tabs li.active {
    font-weight: 800;
}

/* :hyperfiddle.ide/edit */
.-hyperfiddle-ide-edit {
    flex: 1 1;
    overflow-y: auto;
}

@media (min-width: 576px) {
    .-hyperfiddle-ide-edit {
        display: flex;
    }
    .-hyperfiddle-ide-edit > div {
        flex: 1 1 50%;
    }
}

.-hyperfiddle-ide-preview {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.-hyperfiddle-ide-preview > #root.hyperfiddle-preview-stale {
    border-top: 2px solid #ff5050 !important;
}

/* Offset the staleness border so the box doesn't shift */
.-hyperfiddle-ide-preview > #root:not(.hyperfiddle-preview-stale) {
    border-top: 2px solid #def;
}
.-hyperfiddle-ide-preview > #root {
    flex: 1 1;
    overflow: auto;
}

.-hyperfiddle-ide-preview > .loading-page {
    flex: 1 1;
}

.hyperfiddle.field:not(td):not(th) {
    border: 0;
    border-left: 5px solid;
    border-color: inherit;
    padding-left: 1em;
    /*line-height: normal; looks tight but covers up the docstring indicator border*/
}

/* naked and element don't have labels, you want multiple anchors or embeds to stack. see data mode of:
 - http://www.hyperfiddle.site/:cookbook.recipe!table-as-form/
 - http://bitcoin-merchant.hyperfiddle.site/:merchant!invoices-paid/~entity('$',17592186045836)
*/

/* We generate empty field wraps around link slots with no links */
div.hyperfiddle.field + .hyperfiddle.field:not(:empty) {
    padding-top: .8em;
}

.hyperfiddle.fieldset {
    border-left: 5px solid;
    padding: 1.5rem 0;
}

.hyperfiddle.fieldset .hyperfiddle.field {
    border-left: none;
    border-right: 1px solid;
    border-right-color: inherit;
}

.hyperfiddle.fieldset .hyperfiddle.field:first-child {
    border-top: 1px solid;
    padding-top: 0.5rem;
    border-color: inherit;
}

.hyperfiddle.fieldset .hyperfiddle.field:last-child {
    border-bottom: 1px solid;
    padding-bottom: 0.5rem;
    border-color: inherit;
}

.hyperfiddle.fieldset .hyperfiddle.legend {
    padding: 0;
    margin:0;
    padding-left: 1rem;
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: inherit;
    font-weight: 400;
}

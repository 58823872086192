img.hyperfiddle-account-avatar {
    border: 1px solid lightgray;
    border-radius: 50%;
    width: 80px;
    float: left;
    margin-top: 1rem;
    margin-right: 1rem;
}

textarea.hf-route-editor-topnav + .CodeMirror{
    max-height: 2.2rem;
    position: absolute;
    z-index:1;
}

textarea.hf-route-editor-topnav + .CodeMirror:focus-within{
    max-height: initial;
    position: absolute;
    margin-bottom: 1rem;
    box-shadow: 0 2rem 4rem -1rem rgba(0, 0, 0, 0.33);
    padding-bottom: 1rem;
}



/* twbs4 tabs require li.nav-item > a.nav-link
 which recom doesn't generate. These values copied from element inspector on
 https://getbootstrap.com/docs/4.1/components/navs/ */
.rc-tabs > li > a {
    /* .nav-link */
    display: block;
    padding: .5rem 1rem;

    /* .nav-tabs .nav-link */
    border: 1px solid #bbb9;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.rc-tabs > li.active > a {
    color: #495057;
    background-color: #fff;
    border-color: #6669 #6669 transparent;
}
.rc-tabs > li > a:hover {
    border-color: #6669 #6669 transparent;
}

.rc-tabs > li > a .dirty {
    background-color: yellow;
}
html, body {
    /* Omit 'height' - intent is to fit (max content viewport) - impacts background-color */
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 13px;
    line-height: 1.42857143; /* 1.42857143 ~= 20px, but if you set this to exactly 20px, it screws up the top nav */
    color: #333;
}

#root{
    height: 100%;
}

/* Bootstrap overrides */
.popover {
    font-size: unset; /* leave this stuff to userland */
}

label {
    margin-bottom: 0;
}

/* In forms, the label tooltip is block so that the date/time picker is not on the same line */
div.hyperfiddle.field.-db-type-instant > :nth-child(1) {
    display: block;
}

/* put time-picker inline next to date-picker */
div.hyperfiddle.field.-db-type-instant > :nth-child(2),
div.hyperfiddle.field.-db-type-instant > .rc-input-time,
td.hyperfiddle.field.-db-type-instant > :nth-child(1),
td.hyperfiddle.field.-db-type-instant > .rc-input-time {
    display: inline-block; /*display: block;*/ /* it's got recom inline flex styles for no reason */
}

td.hyperfiddle.field.-db-type-instant {
    overflow: hidden; /* recom thing overflows parent width */
    width: 7em; /* recom datepicker min-width is 10em, this chops the trigger which is fine */
}

td.hyperfiddle.field.-db-type-instant span.activator,
td.hyperfiddle.field.-db-type-instant label.form-control {
    /* no widget border inside tables, table border is widget border */
    border: 0; /* 1px solid #a8a8a8; -- there is a cursor:pointer on hover*/
    border-radius: 0;
    height: 22px;
    line-height: 1.4;
    padding-left: 3px;
}

.hyperfiddle.field.-db-type-instant .rc-h-box {
    min-width: unset !important; /* Re-com inline styles this to 10em to make room for the icon, which Hyperfiddle hides */
}

td.hyperfiddle.field.-db-type-instant > .rc-input-time > .time-entry {
    /* no widget border inside tables, table border is widget border */
    border: 0;
}

.hyperfiddle.field.-db-type-instant .dropdown-button {
    height: 24px; /* override from recom value 36px */
    color: inherit;
}

.hyperfiddle.field.-db-type-instant label.dropdown-button {
    padding: 3px 6px; /* override to half recom value "6px 12px" */
}

div.hyperfiddle.field.-db-type-instant .time-entry,
div.hyperfiddle.field.-db-type-instant span.activator,
div.hyperfiddle.field.-db-type-instant label.form-control {
    border: 1px solid #a8a8a8;
    height: 22px;
    line-height: 1.2;
    padding-left: 3px;
}

div.hyperfiddle.field.-db-type-instant label.form-control {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

div.hyperfiddle.field.-db-type-instant span.activator {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

div.hyperfiddle.field.-db-type-instant span.activator,
td.hyperfiddle.field.-db-type-instant span.activator {
    display: none;
}
/* This is low level impl stuff; I do not expect to see
   hyperfiddle abstractions down here. For example no popover adjustments. */

.CodeMirror {
    height: auto; /* This lets the codemirror fill content, and use document scroll */
    width: 100%;
    isolation: isolate;
}

.CodeMirror .parinfer-locus {
    opacity: 0.1;
    pointer-events: none;
}

.CodeMirror.paren .parinfer-locus {
    display: none;
}

input[type="text"],
input[type="password"] {
    box-sizing: border-box;
}

input,
textarea,
.CodeMirror {
    border: 1px #cbd5e0 solid;
    border-radius: 0.25rem;
}

input,
textarea{
    background-color: transparent;
}

input:focus,
textarea:focus{
    background-color: #fff;
}

input[disabled], input[readonly] {
    /* firefox https://github.com/hyperfiddle/hyperfiddle/issues/936 */
    /* background-color: white; */ /* GG: comment out for table row highlight on mouseover. Don’t care about firefox support */
    color: #777; /* was 333 but so subtle */
}

select {
    background-color: transparent;
    width: 100%;
}

.hyperfiddle.field input:disabled,
.hyperfiddle.field select.disabled,
.hyperfiddle.field input.-db-type-boolean.disabled,
.hyperfiddle.field textarea.disabled + .CodeMirror {
    /*color: black;*/
    /*background-color: #f4f4f4; !* Light enough to read graytext *!*/
}

.hyperfiddle.field .-db-type-keyword label.radio-option.disabled {
    /*color: black;*/
}

.hyperfiddle.field > textarea.hyperfiddle.disabled + .CodeMirror .CodeMirror-cursor {
    display: none !important
}

button:disabled {
    /* it also makes it take more space what the heck */
    background: #f4f4f4;
    border: 1px #ccc solid;
    cursor: not-allowed; /* From twbs .btn:disabled, (we dont use .btn, why not?) */
    opacity: .65;
}

/*button, link, inputs, everything */
.invalid:not(.ui),
.rbt-input.is-invalid {
    background-color: #FFF5F7 !important;
    border-color: rgba(255, 20, 100, 0.2) !important;
    border-width: 1px !important;
    /* input.invalid[type="text"] {}*/
    /*border-color: red; commented out because .invalid is applied inconsistently and demos badly */
}

.hyperfiddle-input-group.hyperfiddle-invalid > *:first-child,
table.hyperfiddle tr.hyperfiddle-invalid .hyperfiddle-input-group > *:first-child {
  background-color: #FFF5F7!important;
  border-color: rgba(255, 20, 100, 0.2) !important;
  border-width: 1px !important;
}

.hyperfiddle-input-group.hyperfiddle-invalid > .hyperfiddle-picklist,
table.hyperfiddle tr.hyperfiddle-invalid .hyperfiddle-input-group > .hyperfiddle-picklist {
  /* Picklist has it's own validation css rules */
  border: none !important;
}

.hyperfiddle-input-group.hyperfiddle-invalid > .validation-content,
table.hyperfiddle .hyperfiddle-input-group > .validation-content{
  padding-left: 1em;
  padding-top: 0.5em;
  font-size: 1em;
  color: var(--danger);
}

.hyperfiddle-input-group.hyperfiddle-invalid > .validation-content *::before ,
table.hyperfiddle tr.hyperfiddle-invalid .hyperfiddle-input-group > .validation-content *::before {
  content: "!";
  padding-right: 1em;
}

.hyperfiddle-input-group > .rc-popover-anchor-wrapper {
    width: 100%;
    height: 100%;
}

.hyperfiddle-input-group > .rc-popover-anchor-wrapper > .rc-point-wrapper {
    width: 100%;
    height: 100%;
}

.hyperfiddle-input-group > .rc-popover-anchor-wrapper > .rc-point-wrapper > span {
    width: 100%;
    height: 100%;
}

.hyperfiddle-input-group > .rc-popover-anchor-wrapper > .rc-point-wrapper > span > * {
    width: 100%;
    height: 100%;
}

.hyperfiddle-invalid > .rc-popover-anchor-wrapper > .rc-point-wrapper > .rc-popover-point * {
    color: white;
}

.validation-content {
  list-style: none;
}

.radio-group > * {
    width: auto;
    display: inline-block;
}

.radio-group > label:not(:first-child) { /* horizontal stack */
    margin-left: 0.2em;
}

td > .radio-boolean > .radio-group {
    display: inline-block;
}

label.radio-option {
    font-weight: normal;
}

label.radio-option input {
    margin-right: 3px;
}

/* Scope tightly due to field recursion */
/* http://mbrainz.hyperfiddle.net/:dustingetz!artist/~entity('$',(:artist!gid,~uuid,'5803c81e-739a-4057-9a5c-cf84e55db630')) */
div.hyperfiddle.field.-db-type-keyword > :nth-child(2) > input,
div.hyperfiddle.field.-db-type-long > :nth-child(2) > input,
div.hyperfiddle.field.-db-type-string > :nth-child(2) > input,
div.hyperfiddle.field.-db-type-string > :nth-child(2) > textarea,
div.hyperfiddle.field.-db-type-string > :nth-child(3) > .CodeMirror, /* CodeMirror special dom */
div.hyperfiddle.field.-db-type-ref > :nth-child(2) > input,
div.hyperfiddle.field.-db-type-ref > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-type-ref > :nth-child(2) input.rbt-input-main,
div.hyperfiddle.field.-db-type-uuid > :nth-child(2) > input,
div.hyperfiddle.field.-db-type-uuid > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-ident > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-id > :nth-child(2) > div.input,
div.hyperfiddle.field > :nth-child(2) > div.input {
    border: 1px solid #a8a8a8;
    border-radius: 2px;
    width: 100%;
    color: black;
}

/* This is a fake readonly input that looks like a real input. */
div.hyperfiddle.field.-db-ident > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-id > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-type-string > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-type-ref > :nth-child(2) > div.input,
div.hyperfiddle.field.-db-type-uuid > :nth-child(2) > div.input {
    display: inline-block;
    padding: 1px 0 3px 5px;
    width: 100%; /* hax, need flex layout to get button on the right wihtout fixed width */
    background-color: white; /* look like a readonly input */
    color: black;
}

/* Select very carefully because overflow: hidden can break the recom tooltips
   This is intermittent and not reliably reproducible, see #911 */
td.hyperfiddle.field.-db-type-string > .hyperfiddle-input-group > input, /* wut? These are f'ed */
td.hyperfiddle.field.-db-type-string > .hyperfiddle-input-group > div.input, /* wut? These are f'ed */
td.hyperfiddle.field.-db-ident > .hyperfiddle-input-group > div.input,
td.hyperfiddle.field.-db-id > .hyperfiddle-input-group > div.input,
td.hyperfiddle.field > .hyperfiddle-input-group > div.input,
td.hyperfiddle.field.-db-type-ref > .hyperfiddle-input-group > div.input {
    display: inline-block;
    padding: 4px 0 4px 5px;
    white-space: nowrap; /* slug anchor http://tank.hyperfiddle.site/:dustingetz!slack-storm/ */

    /* This breaks recom tooltips downtree. https://github.com/hyperfiddle/hyperfiddle/issues/911 */
    overflow: hidden; /* :community/orgtype with both rel and hf/new */
}

/* code inline editors */
td.hyperfiddle.field.-db-type-keyword.-db-cardinality-many a,
.code-inline-block a {
    color: inherit; /* This is the edit-code-in-popover link, should not be blue. */
}

/*td.hyperfiddle.field.-db-type-uuid a,*/
/*td.hyperfiddle.field.-db-type-uri a {*/
    /*!*color: black;*! !* was black for domain editor database uri ...? *!*/
    /*padding: 4px 0 4px 5px;*/
/*}*/

td.hyperfiddle.field.-db-type-uri a:hover {
    text-decoration: none;
}

td.hyperfiddle.field code {
    background: unset;
    border: unset;
    padding: unset;
}

/* Hacks to float remove! button right for demo */
td.hyperfiddle.field.-db-id button,
td.hyperfiddle.field.-db-ident button {
    float: right;
}

/* copied from bootstrap .input-group */
/* td.hyperfiddle.field, */
.hyperfiddle-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.hyperfiddle-input-group > *{
  width: 100%;
}

/* copied from bootstrap .input-group>.form-control */
.hyperfiddle-input-group > input,
.hyperfiddle-input-group > div.input {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}


div.rbt-input-multi {
  display: table;
  padding: 0.3em 0.5em;
}

div.rbt-input-multi div.rbt-token {
  float: left;
  padding: 0.08em 0.5rem;
  border-radius: 0.2em;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px; */
  border: 1px solid;
  margin-top: 0.1em;
  margin-right: 0.3em;
}

button.rbt-close {
  margin-left: 5px;
  transform: translateY(-0.07em);
  position: relative;
  z-index: 1;
}

td.hyperfiddle-table-picker-control-cell {
  width: 1.7em;
  padding-left: 0.3em !important;
}

.hyperfiddle-slider-labels {
  width: 100%;
}

.hyperfiddle-slider-label {
  float: left;
  width: 33%;
}

.hyperfiddle-slider-label:nth-child(1) {
  text-align: left;
}

.hyperfiddle-slider-label:nth-child(2) {
  text-align: center;
}

.hyperfiddle-slider-label:nth-child(3) {
  text-align: right;
}

.hyperfiddle-view-mode-selector{
    display: flex;
    border-radius: 0.3rem;
}

.hyperfiddle-view-mode-selector .hyperfiddle-checkbox:first-child{
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-right: none;
}
.hyperfiddle-view-mode-selector .hyperfiddle-checkbox:last-child{
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-left: none;
}

.hyperfiddle-view-mode-selector .hyperfiddle-checkbox{
    border: 1px #cbd5e0 solid;
    overflow: hidden; /* truncate border radius */
    height: 2.2rem;
}

.hyperfiddle-view-mode-selector .hyperfiddle-checkbox:focus-within{
    outline: 3px lightgray solid;
}

.hyperfiddle-view-mode-selector .hyperfiddle-checkbox:hover{
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
}

.hyperfiddle-view-mode-selector label{
    cursor:pointer;
    padding: 0 0.75rem;
    height: 100%;
}

.hyperfiddle-view-mode-selector input[type="radio"]{
    opacity: 0; /* preserves tab focus for a11y */
    position: absolute;
    z-index:-1; /* hide */
}
.hyperfiddle-view-mode-selector input[type="radio"]:checked + label{
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.10);
    font-weight: 600;
}

/* div and td */
.hyperfiddle.field.-db-type-ref > .rbt .rbt-input-main.form-control.rbt-input {
    padding: 1px 0 3px 5px;
    height: unset;
    color: black;
}


/* attached to document.body, no hyperfiddle selector */
.rbt-menu .dropdown-item {
    padding: 1px 0 3px 5px;
}

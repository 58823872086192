div.ui.hyperfiddle-schema table {
    table-layout: fixed;
}
div.ui.hyperfiddle-schema th.naked {
    width: 60px;
}

div.hyperfiddle-schema > table th.-db-isComponent,
div.hyperfiddle-schema > table th.-db-fulltext {
    width: 20px;
}

div.hyperfiddle-schema > table th.-db-valueType,
div.hyperfiddle-schema > table th.-db-cardinality,
div.hyperfiddle-schema > table th.-db-unique {
    width: 90px;
}

div.hyperfiddle-schema > table th.-db-ident {
    width: 300px;
}
